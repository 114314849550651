.form-group {
  padding: 4px 8px 4px 8px;
}

.form-control:focus {
  border-color: orange !important;
  outline: 0 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(229, 84, 32, 0.6) !important;
}

%invalid {
  border: 1px solid red;
  background-color: #FCF3F3;
}

input.ng-invalid:not(.ng-pristine) {
  @extend %invalid;
}

.text-muted {
  color: #999;
}

input.ng-invalid:not(.ng-pristine) {
  @extend %invalid;
}

forms-text.ng-invalid:not(.ng-pristine) {
  input {
    @extend %invalid;
  }
}

forms-text-addon.ng-invalid:not(.ng-pristine) {
  input {
    @extend %invalid;
  }
}

forms-numeric {
  input {
    text-align: right;
  }
}

forms-numeric.ng-invalid:not(.ng-pristine) {
  input {
    @extend %invalid;
    text-align: right;
  }
}

.ui-select-container.ng-invalid:not(.ng-pristine) {
  div > span {
    @extend %invalid;
  }
}

forms-text-area.ng-invalid:not(.ng-pristine) {
  textarea {
    @extend %invalid;
  }
}

area.ng-invalid:not(.ng-pristine) {
  input {
    @extend %invalid;
  }
}

.btn-group {
  label.ng-invalid:not(.ng-pristine) {
    @extend %invalid;
  }
}

forms-datepicker.ng-invalid:not(.ng-pristine) {
  input {
    @extend %invalid;
    border-right: inherit;
  }

  span.input-group-addon {
    @extend %invalid;
    border-left: inherit;
  }
}

forms-file.ng-invalid:not(.ng-pristine) {
  div.form-control {
    @extend %invalid;
    border-right: inherit;
  }

  label.input-group-addon {
    @extend %invalid;
    border-left: inherit;
  }
}

forms-date-timepicker.ng-invalid:not(.ng-pristine) {
  input {
    @extend %invalid;
    border-right: inherit;
  }

  span.input-group-addon {
    @extend %invalid;
    border-left: inherit;
  }
}

.uc-required-message-container {
  text-align: center;

  .uc-required-message {
    padding: 10px;
    background-color: darkred;
    border-radius: 25px;
    color: white;
    text-align: center;
    font-weight: bold;
    line-height: 50px;
  }
}

.k-combobox.ng-invalid.ng-dirty {
  input {
    background-color: #FCF3F3;
  }
}


