// Alerts

@mixin alert-variant($background, $border, $body-color) {
  color: $body-color;
  background-color: $background;
  border-color: $border;

  hr {
    border-top-color: darken($border, 5%);
  }
  .alert-link {
    color: darken($body-color, 10%);
  }
}
