$border-radius: 0.25rem;
$primary: #e55420;
$secondary: #e4e7eb;
$info: #17a2b8;
$success: #28a745;
$warning: #ffc107;
$error: #dc3545;
$body-text: #212529;
$body-bg: #ffffff;
$headings-text: #212529;
$subtle-text: #6c757d;
$disabled-text: #8f8f8f;
$component-text: #212529;
$component-bg: #ffffff;
$base-text: #212529;
$base-bg: #f7f7f7;
$hovered-text: #16181b;
$hovered-bg: #e9ecef;
$selected-text: #ffffff;
$selected-bg: #007bff;
$button-text: #212529;
$button-bg: #e4e7eb;
$link-text: #007bff;
$link-hover-text: #0056b3;
$series-a: #0275d8;
$series-b: #5bc0de;
$series-c: #5cb85c;
$series-d: #f0ad4e;
$series-e: #e67d4a;
$series-f: #d9534f;

$font-family: Lato, Arial, sans-serif !default;

@import "~@progress/kendo-theme-bootstrap/dist/all.scss";
@import "~bootstrap/dist/css/bootstrap";

