@import "src/scss/custom-variables";


.sidebar {

  &.sidebar-hidden {
    flex: 0 0 0;
  }

  .sidebar-nav {
    .nav {

      .nav-link {
        transition: .3s;

        [class^="icon-"] {
          color: white !important;
        }

        :not(.menuSubItem) {
          text-transform: uppercase;
        }
      }

      .menuSubItem {
        background-color: #414550 !important;
      }

      .nav-link:not(.active):hover {
        background-color: $escs-gray !important;
        color: white !important;

        [class^="icon-"] {
          color: $escs-orange !important;
        }
      }

      .nav-link.active {
        color: white !important;
        background-color: $escs-orange !important;

        :hover {
          background-color: $escs-orange !important;
        }

        [class^="icon-"] {
          color: white !important;
        }
      }
    }

  }
}

