@import "../custom-variables";

.app-header.navbar {

  .navbar-brand {
    padding: 10px;

    .brand-title {
      font-size: 14px;
      font-weight: bold;
      color: $primary-color;
    }

    .img-logo {
      content: $navbar-brand-logo;
      max-height: 100%;
      margin: 2px;
    }
  }

  .navbar-toggler:focus, .navbar-toggler{
    color: $menu-background-color;
    border-radius: 4px;
    transition: .3s;

    &:hover {
      background-color: #EEEEEE;
    }
  }
}
