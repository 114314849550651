$color: #E55420;
$hover-color: darken($color, 10%);

.p-picklist {
  .p-picklist-list {
    .p-picklist-item {
      padding: 0px 0px;
    }
  }
  .p-picklist-header {
    padding: 5px 10px;
  }
  .p-picklist-filter-container {
    padding: 5px 10px;

    input {
      padding: 0;
      border: none;
    }
    input:focus {
      box-shadow: none;
    }
  }
}

.p-button {
  color: #ffffff;
  background: $color;
}
.p-button:enabled:hover {
  background: $hover-color;
  color: #ffffff;
  border-color: $hover-color;
}
.p-button:enabled:active {
  background: $color;
  color: #ffffff;
  border-color: $color;
}

