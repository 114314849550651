@media screen {
  .print-container * {
    display: none;
  }
}

@media print {
  .no-print {
    display: none;
  }

  .print-container * {
    visibility: visible;
  }
}


